.location-details {
  height: max-content;
  width: max-content;
  position: relative;
  top: 10px;
  left: 30px;
  background: #f1f3f4;
  font-size: 26px;
  font-family: 'Roboto';

  .custom-margin {
    margin-left: 69px;
  }

  .primary-badge,
  .standby-badge,
  .not-respond {
    padding: 3px 7px;
    font-size: 18px;
    border-radius: 10px;
    color: white;
    font-weight: 400;
    // letter-spacing: 1.1px;
  }

  .primary-badge {
    border: 1px solid red;
    background: red;
  }
  .standby-badge {
    border: 1px solid #ffc107;
    background: #ffc107;
  }
  .not-respond {
    border: 1px solid #29d0f2;
    background: #29d0f2;
  }
  .badge-container {
    margin-bottom: 6px;
  }

  @media screen and (max-width: 1399px) {
    font-size: 16px;
    .custom-margin {
      margin-left: 48px;
    }
    .primary-badge,
    .standby-badge,
    .not-respond {
      font-size: 15px;
    }
    .badge-container {
      margin-bottom: 4px;
    }
  }

  @media screen and (max-width: 1199px) {
    font-size: 14px;
    .custom-margin {
      margin-left: 45px;
    }
    .primary-badge,
    .standby-badge,
    .not-respond {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .badge-container {
      margin-bottom: 3px;
    }
  }
}
