.btn-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.traffic-button {
  padding: 3px;
  height: max-content;
  width: max-content;
  border-radius: 5px;
}
.add-layer {
  color: white;
  background: green;
  border: 1px solid green;
  &:hover {
    background: #006600;
    border: 1px solid #006600;
  }
}
.remove-layer {
  color: white;
  background: red;
  border: 1px solid red;

  &:hover {
    background: #e60000;
    border: 1px solid #e60000;
  }
}
