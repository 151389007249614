.confirmation-btn {
  padding: 3px;
  height: max-content;
  width: max-content;
  border-radius: 5px;
  color: white;
  background: green;
  border: 1px solid green;

  &:hover {
    background: #006600;
    border: 1px solid #006600;
  }
}
