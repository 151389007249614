@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
.leaflet-popup-content-wrapper {
  background: black;
  .leaflet-popup-content h6 {
    color: white !important;
  }
}
.leaflet-popup-tip-container {
  .leaflet-popup-tip {
    background: black !important;
  }
}
.leaflet-popup-close-button {
  span {
    color: #b3b3b3;
  }
}
.sos_app_content {
  padding: 10px;
}
.sos_app_container {
  padding: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.extra-width {
  width: 101.5% !important;
}
.ms-85 {
  margin-left: 85px;
}
.text-red-500 {
  color: #dc3545;
}

.modal-header {
  padding-top: 20px;
  padding-bottom: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.page-container {
  height: calc(100vh - 125px) !important;
  width: 100%;
}

.btn-disable {
  pointer-events: none;
  opacity: 0.6;
}

.prevent-click {
  pointer-events: none;
}

// logo
.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.irs-logo {
  height: 45px !important;
}
.logo-text {
  color: white;
  font-weight: bold;
  letter-spacing: 1.4px;
  font-size: 22px;
}
.minimize-logo {
  height: 35px !important;
}

.round-img {
  border: 1px solid #cccccc;
  border-radius: 50%;
}
