.incident-places {
  .search-box {
    width: 80%;
    position: relative;
  }
  .set-icon-position {
    position: absolute;
    top: 14px;
    right: 15px;
  }
  .fa-search {
    font-size: 18px;
  }
}
