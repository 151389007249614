.map-container {
  height: calc(100vh - 125px);
  width: 100%;

  .leaflet-routing-container-hide {
    display: none;
    height: 0;
    width: 0;
  }
}
